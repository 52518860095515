const CURRENCY_VALUES = [
    { id: 0, symbol: "$", label: "USD", ratio: 1 },
    { id: 1, symbol: "€", label: "EURO", ratio: 0.93 },
    { id: 2, symbol: "£", label: "GBP", ratio: 0.8 },
];

const DEFAULT_CURRENCY = CURRENCY_VALUES[0];

const PRICING_TABLE_VALUES = {
    expand: "pricing-section-expand-text",
    collapse: "pricing-section-collapse-text",
};
/**
 * Exported constants.
 */
export { CURRENCY_VALUES, DEFAULT_CURRENCY, PRICING_TABLE_VALUES };
