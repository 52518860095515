/**
 * MainNavConstants object holds the different heights for the navigation bar.
 * @type {Object}
 */
const MainNavConstants = {
    NAV_HEIGHT_SM: 81,
    NAV_HEIGHT_LG: 114,
    NAV_HEIGHT_MOBILE: 60,
};

export default MainNavConstants;
