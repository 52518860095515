module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P7VJSX","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"site":"www"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/icons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2d8023c3b2369d28e981716a746231f0"},
    },{
      plugin: require('../../../libs/gatsby-plugins/gatsby-plugin-marketo/gatsby-browser.js'),
      options: {"plugins":[],"munchkinId":"049-DKK-796","additionalProps":{"altIds":[]}},
    },{
      plugin: require('../../../libs/gatsby-plugins/gatsby-plugin-search-unify-tracking/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../libs/gatsby-plugins/gatsby-plugin-nav-tracking/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../libs/gatsby-plugins/gatsby-plugin-onetrust-modal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../libs/gatsby-plugins/gatsby-plugin-6sense-ada/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../libs/gatsby-plugins/gatsby-plugin-vidyard-api/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../libs/gatsby-plugins/gatsby-plugin-preview-redirects/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
