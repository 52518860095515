/**
 * Attempt to render the vidyard video players on page load.
 * Added a delay loop here to essentially wait for the window object to be available
 * and then render the players via the Vidyard API imported via UMD script in gatsby-ssr.js
 */
exports.onRouteUpdate = async () => {
    // Max attempts calculation: 5 seconds / 500ms
    const maxAttempts = 10;
    let attempts = 0;

    // Function to delay execution
    const delay = (duration) => new Promise((resolve) => setTimeout(resolve, duration));

    // Function to check condition and execute code
    const attemptExecution = async () => {
        // Increment attempt count
        attempts += 1;

        // Check conditions are perfect
        if (typeof window.vidyardEmbed === "object") {
            window.vidyardEmbed.api.renderDOMPlayers();

            // console.log("sending the adaData object", adaData);
        } else if (attempts < maxAttempts) {
            // If condition not met and max attempts not reached, try again after 500ms
            await delay(500);
            await attemptExecution();
        } else {
            // Max attempts reached, do nothing
        }
    };

    await attemptExecution();
};
