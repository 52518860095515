exports.onRouteUpdate = ({ location, prevLocation }) => {
    // Data attribute to see if the click handler already exists
    const clickAttribute = "navClickHandler";

    /**
     * THIS FUNCTION IS FOR TESTING ONLY!
     * Stops the propagation of an event.
     *
     * @param {Event} event - The event to be stopped.
     */
    // const stopEvents = (event) => {
    //     event.preventDefault();
    //     event.stopImmediatePropagation();
    //     event.stopPropagation();
    // };

    // Create the object to send to the dataLayer
    const navClickEvent = {
        event: "top-nav-click",
        primary_nav_name: undefined,
        secondary_nav_name: undefined,
        tertiary_nav_name: undefined,
        "nav_location_click_name==": undefined, // primary, secondary, tertiary, eyebrow
    };

    /**
     * Retrieves the text of the top-level element identified by the specified ID.
     *
     * @param {string} id - The ID of the element to retrieve the text from.
     * @return {string | undefined} - The text of the element, or undefined if the element is not found.
     */
    const getTopLevelTextById = (id) =>
        document.querySelector(`header .main-menu > li > a[data-dropdown-id="${id}"]`)?.innerText;

    // Get mainMenu links
    const mainMenuLinks = document.querySelectorAll(`header .main-menu > li > a`);

    // Get 2nd level links (desktop)
    const secondMenuLinks = document.querySelectorAll(`header .dropdownWrapper .submenu-level-2-wrapper ul a`);

    // Get 2nd level links (mobile)
    const secondMenuLinksMobile = document.querySelectorAll("header .mobileSubmenuLevelTwo > ul > li > a");

    // Get 3rd level links (desktop)
    const thirdMenuLinks = document.querySelectorAll(
        `header .dropdownWrapper .submenu-level-3-wrapper .submenu-level-3 a`
    );

    // Get 3rd level links (mobile)
    const thirdMenuLinksMobile = document.querySelectorAll("header .mobileSubmenuLevelThree .submenu-level-3 a");

    // TESTING: Print the number of links
    // The number of links for desktop and mobile should be the same for each level
    // console.table({
    //     mainMenuLinks: mainMenuLinks.length,
    //     secondMenuLinks: secondMenuLinks.length,
    //     thirdMenuLinks: thirdMenuLinks.length,
    //     secondMenuLinksMobile: secondMenuLinksMobile.length,
    //     thirdMenuLinksMobile: thirdMenuLinksMobile.length,
    // });

    /* Traverse through the links and add the event object to the click handlers */

    /**
     * MAIN MENU LINKS
     */

    // Traverse through the main menu links
    mainMenuLinks.forEach((link) => {
        // If the data attribute set in 'clickAttribute' is not set, then continue
        if (!link.dataset[clickAttribute]) {
            // Add the click event listener
            link.addEventListener("click", (event) => {
                // Create the object to send to the dataLayer
                window.dataLayer = window.dataLayer || [];

                // Get Primary Nav Link Text (current)
                navClickEvent.primary_nav_name = link.innerText;
                navClickEvent["nav_location_click_name=="] = "primary";

                // Send the object to the dataLayer
                window.dataLayer.push(navClickEvent);

                // Testing: Stop the rest of the events from being triggered
                //stopEvents(event);
            });

            // Add the click handler
            link.dataset[clickAttribute] = "true";
        }
    });

    /**
     * 2nd LEVEL LINKS (desktop & mobile)
     */

    // Traverse through the second menu links (desktop)
    secondMenuLinks.forEach((link) => {
        // If the data attribute set in 'clickAttribute' is not set, then continue
        if (!link.dataset[clickAttribute]) {
            // Add the click event listener
            link.addEventListener("click", (event) => {
                // debugger;
                // Create the object to send to the dataLayer
                window.dataLayer = window.dataLayer || [];

                // Get Primary Nav Link Text - <div id="xxxx" class="submenu"></div>
                const mainMenuId = link.closest(".submenu").id;
                navClickEvent.primary_nav_name = getTopLevelTextById(mainMenuId);

                // Get the Secondary Nav Link Text (current link)
                navClickEvent.secondary_nav_name = link.innerText;
                navClickEvent["nav_location_click_name=="] = "secondary";

                // Send the object to the dataLayer
                window.dataLayer.push(navClickEvent);

                // Testing: Stop the rest of the events from being triggered
                // stopEvents(event);
            });

            // Add the click handler
            link.dataset[clickAttribute] = "true";
        }
    });

    // Traverse through the second menu links (mobile)
    secondMenuLinksMobile.forEach((link) => {
        // If the data attribute set in 'clickAttribute' is not set, then continue
        if (!link.dataset[clickAttribute]) {
            // Add the click event listener
            link.addEventListener("click", (event) => {
                // debugger;
                // Create the object to send to the dataLayer
                window.dataLayer = window.dataLayer || [];

                // Get Primary Nav Link Text
                const mainMenuId =
                    link.closest(".mobileSubmenuLevelTwo").dataset.parentDropdownId ||
                    link.closest(".mobileSubmenuLevelTwo").id;
                navClickEvent.primary_nav_name = getTopLevelTextById(mainMenuId);

                // Get the Secondary Nav Link Text (current link)
                navClickEvent.secondary_nav_name = link.innerText;
                navClickEvent["nav_location_click_name=="] = "secondary";

                // Send the object to the dataLayer
                window.dataLayer.push(navClickEvent);

                // Testing: Stop the rest of the events from being triggered
                // stopEvents(event);
            });

            // Add the click handler
            link.dataset[clickAttribute] = "true";
        }
    });

    /**
     * 3rd LEVEL LINKS (desktop & mobile)
     */
    // Traverse through the third menu links
    thirdMenuLinks.forEach((link) => {
        // If the data attribute set in 'clickAttribute' is not set, then continue
        if (!link.dataset[clickAttribute]) {
            // Add the click event listener
            link.addEventListener("click", (event) => {
                // Create the object to send to the dataLayer
                window.dataLayer = window.dataLayer || [];

                // Get Primary Nav Link Text - <div id="xxxx" class="submenu"></div>
                const mainMenuId = link.closest(".submenu").id;
                navClickEvent.primary_nav_name = getTopLevelTextById(mainMenuId);

                // Get the Secondary Nav Link Text - <div id="xxxx" class="submenu"></div>
                const secondMenuText = link.closest(".submenu").querySelector(".submenu-level-2-wrapper li.active a")
                    ?.innerText;
                navClickEvent.secondary_nav_name = secondMenuText;

                // Get the Tertiary Nav Link Text (current link)
                navClickEvent.tertiary_nav_name = link.innerText;
                navClickEvent["nav_location_click_name=="] = "tertiary";

                // Send the object to the dataLayer
                window.dataLayer.push(navClickEvent);

                // Testing: Stop the rest of the events from being triggered
                // stopEvents(event);
            });

            // Add the click handler
            link.dataset[clickAttribute] = "true";
        }
    });

    // Traverse through the third menu links (mobile)
    thirdMenuLinksMobile.forEach((link) => {
        // If the data attribute set in 'clickAttribute' is not set, then continue
        if (!link.dataset[clickAttribute]) {
            // Add the click event listener
            link.addEventListener("click", (event) => {
                // debugger;
                // Create the object to send to the dataLayer
                window.dataLayer = window.dataLayer || [];

                // Get Primary Nav Link Text
                const mainMenuId =
                    link.closest(".mobileSubmenuLevelTwo").dataset.parentDropdownId ||
                    link.closest(".mobileSubmenuLevelTwo").id;
                navClickEvent.primary_nav_name = getTopLevelTextById(mainMenuId);

                // Get the Secondary Nav Link Text
                const secondMenuId = link.closest(".mobileSubmenuLevelThree").dataset.levelTwoChildDropdown;
                const secondMenuText = link
                    .closest(".mobileSubmenuLevelTwo")
                    .querySelector("li[data-level-two-parent-dropdown='" + secondMenuId + "'] > a")?.innerText;
                navClickEvent.secondary_nav_name = secondMenuText;

                // Get the Tertiary Nav Link Text (current link)
                navClickEvent.tertiary_nav_name = link.innerText;
                navClickEvent["nav_location_click_name=="] = "tertiary";

                // Send the object to the dataLayer
                window.dataLayer.push(navClickEvent);

                // Testing: Stop the rest of the events from being triggered
                // stopEvents(event);
            });

            // Add the click handler
            link.dataset[clickAttribute] = "true";
        }
    });
};

exports.onClientEntry = () => {
    const navClickEvent = {
        event: "top-nav-click",
        primary_nav_name: undefined,
        secondary_nav_name: undefined,
        tertiary_nav_name: undefined,
        "nav_location_click_name==": undefined, // primary, secondary, tertiary, eyebrow
    };

    const gaEventHandler = (event) => {
        window.dataLayer = window.dataLayer || [];

        navClickEvent.primary_nav_name = event.detail.primaryNavName;
        navClickEvent.secondary_nav_name = event.detail.secondaryNavName;
        navClickEvent.tertiary_nav_name = event.detail.tertiaryNavName;
        navClickEvent["nav_location_click_name=="] = "eyebrow";

        // Send the object to the dataLayer
        window.dataLayer.push(navClickEvent);
    };

    // Listen for this event
    window.addEventListener("ga-event", gaEventHandler);
};
