import UTMPurser from "./index";

// Adds (increments) page views to the Purser Object
class Pageviews {
    static add() {
        let attributes = UTMPurser.get();
        if (attributes) {
            attributes.pageviews = attributes.pageviews + 1 || 1;
            return UTMPurser.update(attributes);
        }
    }
}

export default Pageviews;
