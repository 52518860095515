import React, { createContext, useState, useContext, useMemo } from "react";
import MainNavConstants from "@qlik/qlik/src/lib/constants/main-nav-constants";
import { DEFAULT_CURRENCY } from "@qlik/qlik/src/lib/constants/pricing-constants";

/**
 * AppContext is a React context object.
 * It is used to share certain state values across the entire app.
 */
const AppContext = createContext({});

/**
 * AppProvider is a React component that provides the AppContext to its children.
 * @param {object} props - The properties passed to this component.
 * @param {ReactNode} props.children - The child components of this provider.
 */
const AppProvider = ({ children }) => {
    const [headerHeight, setHeaderHeight] = useState(MainNavConstants.NAV_HEIGHT_LG);
    const [notificationExists, setNotificationExists] = useState(false);
    const [secondaryNotificationExists, setSecondaryNotificationExists] = useState(false);
    const [siteScrolledDown, setSiteScrolledDown] = useState(false);
    const [currency, setCurrency] = useState(DEFAULT_CURRENCY);
    const [bodyClassName, setBodyClassName] = useState("");
    const [pricingFeaturedColumns, setPricingFeaturedColumns] = useState([]);

    const contextValue = useMemo(
        () => ({
            headerHeight,
            setHeaderHeight,
            notificationExists,
            setNotificationExists,
            siteScrolledDown,
            setSiteScrolledDown,
            currency,
            setCurrency,
            secondaryNotificationExists,
            setSecondaryNotificationExists,
            bodyClassName,
            setBodyClassName,
            pricingFeaturedColumns,
            setPricingFeaturedColumns,
        }),
        [headerHeight, notificationExists, secondaryNotificationExists, siteScrolledDown, currency, bodyClassName]
    );

    return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

/**
 * useAppContext is a custom hook that provides a convenient way to access the AppContext.
 * @returns {object} The current context value.
 */
const useAppContext = () => useContext(AppContext);

export { AppProvider, useAppContext };
