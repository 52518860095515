exports.onRouteUpdate = async ({ location, prevLocation }) => {
    // Exit if the GzAnalytics object isn't available.
    if (!window.GzAnalytics) return null;

    // Only trigger on route changes in the SPA architecture.
    // On page reloads, hard refreshes, the script in the gatsby-ssr.js will pick it up.
    if (location && prevLocation && location?.pathname !== prevLocation?.pathname) {
        window.GzAnalytics.sendAnalytics("pageView", {});
    }
};
