import { attemptExecution } from "./plugin-utils";
import { createI18nHelper } from "@qlik/utils/i18nHelper";

export const send6SenseDataToAda = async () => {
    const conditionFn = () =>
        window._storagePopulated === true &&
        typeof window.adaEmbed === "object" &&
        typeof window.localStorage._6senseCompanyDetails === "string";

    const successFn = async () => {
        const sixSenseData = JSON.parse(window.localStorage._6senseCompanyDetails);

        if (sixSenseData.company.company_match !== "Match") {
            return;
        }

        const segmentIds = sixSenseData.segments.ids.map((id) => `[${id}]`).join(" ");

        let adaData = {
            "6s_country": sixSenseData.company.country,
            "6s_employee_range": sixSenseData.company.employee_range,
            "6s_industry": sixSenseData.company.industry,
            "6s_name": sixSenseData.company.name,
            "6s_region": sixSenseData.company.region,
            "6s_revenue_range": sixSenseData.company.revenue_range,
        };

        if (segmentIds !== "") {
            adaData["6s_segments"] = segmentIds;
        }
        await window.adaEmbed.setMetaFields(adaData);
        // console.log("6sense data sent to Ada.", adaData);
    };

    await attemptExecution(conditionFn, successFn);
};

export const updateChatbotLanguage = async (location) => {
    // Note: We can't use the `usei18n` hook here because this function is not a React component and doesn't have access to the React context.
    // https://react.dev/reference/rules/rules-of-hooks
    let i18n = createI18nHelper();
    i18n = createI18nHelper(i18n.get("contentfulCode").from("pathPrefix", i18n.getPathLangPrefix(location.pathname)));

    const conditionChatLangFn = () => typeof window.adaEmbed === "object";

    const successChatLangFn = async () => {
        await window.adaEmbed.reset({
            language: i18n?.curr?.langCode || "en",
        });
        // console.log("Chatbot language updated to ", i18n?.curr?.langCode || "en");
    };
    await attemptExecution(conditionChatLangFn, successChatLangFn);
};
