module.exports = {
    en: {
        langName: "English",
        enLangName: "English",
        langCode: "en",
        pathPrefix: "/us",
        locale: "en-US",
        contentfulCode: "en",
    },
    "de-DE": {
        langName: "Deutsch",
        enLangName: "German",
        langCode: "de",
        pathPrefix: "/de-de",
        locale: "de-DE",
        contentfulCode: "de-DE",
    },
    "es-ES": {
        langName: "Español",
        enLangName: "Spanish",
        langCode: "es",
        pathPrefix: "/es-es",
        locale: "es-ES",
        contentfulCode: "es-ES",
    },
    "fr-FR": {
        langName: "Français",
        enLangName: "French",
        langCode: "fr",
        pathPrefix: "/fr-fr",
        locale: "fr-FR",
        contentfulCode: "fr-FR",
    },
    "it-IT": {
        langName: "Italiano",
        enLangName: "Italian",
        langCode: "it",
        pathPrefix: "/it-it",
        locale: "it-IT",
        contentfulCode: "it-IT",
    },
    "pt-BR": {
        langName: "Português",
        enLangName: "Portuguese",
        langCode: "pt",
        pathPrefix: "/pt-br",
        locale: "pt-BR",
        contentfulCode: "pt-BR",
    },
    "ja-JP": {
        langName: "日本語",
        enLangName: "Japanese",
        langCode: "ja",
        pathPrefix: "/ja-jp",
        locale: "ja-JP",
        contentfulCode: "ja-JP",
    },
};
