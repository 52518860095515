// Normalize URL by removing duplicates and triplicates, but retaining protocols.
const normalizeUrl = function(url) {
    return `${url}/`.replace(/([^:]\/)\/+/g, "$1").replace(/\/$/, "");
};

// Normalize path by wrapping in slashes, then removing duplicates and triplicates.
const normalizePath = function(path) {
    return path ? `/${path}/`.replace(/\/{2,}/g, "/").replace(/\/$/, "") : "/";
};

module.exports.normalizeUrl = normalizeUrl;
module.exports.normalizePath = normalizePath;
