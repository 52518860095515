// Utility functions for the 6sense ADA plugin
const delay = (duration) => new Promise((resolve) => setTimeout(resolve, duration));

export const attemptExecution = async (conditionFn, successFn, maxAttempts = 10, delayDuration = 500) => {
    let attempts = 0;

    const execute = async () => {
        attempts += 1;

        if (conditionFn()) {
            successFn();
        } else if (attempts < maxAttempts) {
            await delay(delayDuration);
            await execute();
        } else {
            console.log("Max attempts reached, exiting.");
        }
    };

    await execute();
};
